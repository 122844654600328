import React, { useState } from 'react'
import '../styles/software-training-institute.css'
import Layout from '../components/layout'
import Slider from 'react-slick'
import { Table, Accordion, Card, Button } from 'react-bootstrap'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import CourseForm from '../components/CourseForm'
import { faArrowDown, faArrowUp, faAngleDoubleDown, faAngleDoubleUp } from '@fortawesome/free-solid-svg-icons'


const stiFaqs = [
    {
        "id": 1,
        "question": "Which are the best software training institutes in chennai?",
        "answer": "There are so many software Training institutes in Chennai to learn various courses. You can also learn software programs and technical skills for scripting a program from a famous training institute which is softlogic, FITA, Udacity and Inventateq they provide various courses but the price they are offering is good like crampete. View the course and topics covered in the syllabus from the menu of the website in a single-page and get registered to become master."
    },
    {
        "id": 2,
        "question": "What are the fees for a software training course?",
        "answer": "It completely depends on how much the cost of the product itself is and if there are recurring fees. The data bases from students or user-experience says some software training institutes charge anywhere from 5000rs to 25000rs  a month for the product but with that the island of amenities includes unlimited training, updates and support. And also with 100% placement assurance. You can see bachelor’s degree students hands-on with data science, DBA,html5,data structures,aws,json, Saas,ruby on rails developer,front end developer, nanodegree, deploy in nosql and SAP and web development courses completed by coders working at Oracle and Google as software engineers."
    },
    {
        "id": 3,
        "question": "What are the different courses offered by computer software training institutes in Chennai?",
        "answer": "Degree in computer science, information-systems and software-engineering alone cannot make a person a successful one. The courses play a vital role. Learning new web technologies in computer systems makes a person to shine bright.SLA, DBA, mysql,angularjs,computer-programming,front end interface and back end software courses and some web technologies frameworks like ruby on rails,ruby jquery etc which work as an user-interface between client side and server side,fundamentals and introduction to digital marketing and operating systems are the different courses offered by software training institutes in Chennai."
    },
    {
        "id": 4,
        "question": "Do the institutes provide a certificate at the end of the course?",
        "answer": "They offer certificates of completion for both paid and free courses. Some institutes also offer bootcamp. They do this for a few reasons: 1. Encourage students at the entry-level to finish the course in web programming languages completely to make them get settled in a good occupation. Creating a foundation to make them understand how to code and enlighten them how to become a web dev,web designers through certification.Tons of students never finish a course so this is a way for them to encourage these students to finish. 2. Gives them a certificate that they can add to their Linkedin profile, resume, etc. I think this is a way for them to show off their accomplishments. I agree that a final exam would be a great way to make sure that they actually learned."
    },
    {
        "id": 5,
        "question": "Do these institutes conduct weekend classes for working students?",
        "answer": "Yes, some institutes conduct weekend classes for working or freelance students to become web developers, web-designers and programmers. Some startups institutes also have weekend classes.They have different batch timings which would benefit the students. All you need to search for a best software training institute in Chennai, click to continue the menu and a toggle menu opens where you can view the courses in the Chennai institutes for weekend classes. Trainers at the institutes collaborate with each other to make you understand more on the subject."
    },
    {
        "id": 6,
        "question": "What's the salary for a student after completing a short term course?",
        "answer": "Many software training institutes in Chennai turn students into a backend developer or a frontend developer with a high median annual salary. Web developer jobs or web engineers are abundant in the world where students get into the jobs after completing web development training and have accessibility to design and develop web-pages, to design,to write code  and debugging programs, web programmers, php web developer, web-page application development to become a professional web developer or best web development. The beginner's web developer salary would be scalable at 2.5L to 3L. That’s the annual salary for the developer where they make up. However the package differs by locations. Students enhance their developer skills-set more by writing code, mastering in problem-solving techniques, writing command-line to web browsers to get higher pay in the company where they are working."
    },
    {
        "id": 7,
        "question": "Is doing courses in the training institutes useful?",
        "answer": "Learning an immersive course always adds a point to your resume. Doing an additional course always meets up the recruiter's expectations on hiring you. The course plugins you to learn to code and teaches you how to create a program and helps you to create a web page in a responsive design that looks good on all devices etc. By learning the short term courses a person can gain more knowledge on the subject where you want to be into."
    }
]

const courseSchema = `{ "@context": "https://schema.org",
"@type": "Course",
"name": "Sotware Training Institute In Chennai with Job Placement",
"description": "Crampete is the no.1 software training institute in Chennai with 100% job placement. This best coaching centre offers IT training courses for the student and freshers.",
"provider": {
  "@type": "Organization",
  "name": "Crampete",
  "sameAs": "https://crampete.com/software-training-institute-in-chennai/" } 
}`;

const videoSchema = `{ "@context": "https://schema.org",
"@type": "VideoObject",
"name": "Sotware Training Institute In Chennai with Job Placement",
"description": "Crampete is the no.1 software training institute in Chennai with 100% job placement. This best coaching centre offers IT training courses for the student and freshers.",
"thumbnailUrl": [
  "https://crampete-staticfiles.s3.ap-south-1.amazonaws.com/images/software-training-institute-in-chennai/crampete-about-video-thumbnail.jpg"
 ],
"uploadDate": "2021-02-02T11:46:47.599Z",
"duration": "PT0.2M",
"contentUrl": "https://crampete-staticfiles.s3.ap-south-1.amazonaws.com/video/software-training-institute-in-chennai.mp4",
"embedUrl": "https://crampete-staticfiles.s3.ap-south-1.amazonaws.com/video/software-training-institute-in-chennai.mp4" }`;

const productSchema = `{
    "@context": "http://schema.org",
    "@type": "Product",
    "url":"https://www.crampete.com/software-training-institute-in-chennai/",
    "brand": {
                 "@type": "Brand",
                 "name": "Crampete"
             },
    "sku":"40",
    "gtin12":"40",
    "aggregateRating": {
        "@type": "AggregateRating",
        "ratingValue": "4.8",
        "reviewCount": "26850"
    },
    "description": "Crampete is the no.1 software training institute in Chennai with 100% job placement. This best coaching centre offers IT training courses for the student and freshers.",
    "name": "Software Training Institute in Chennai with Job Placement",
    "image": "https://www.crampete.com/images/logo.svg",
    "offers":  {
        "@type": "Offer",
        "url":"https://www.crampete.com/software-training-institute-in-chennai/",
        "availability": "http://schema.org/InStock",
        "price": "78900",
        "priceCurrency": "&#8377;",
        "priceValidUntil":"20-06-2024"
    },
    "review":{ "@type": "Review","reviewBody": "Before enrolling with Crampete, I had zero knowledge about software programming and I wanted to pursue a career in the same. The course at Crampete changed my career upfront. I am enriched with knowledge about software programming due to the well experienced friendly trainers at Crampete. A job at Hexaware as a Full Stack Developer is the crowning glory which is my dream come true.","reviewRating": {
                        "@type": "Rating",
                        "ratingValue": "5",
                        "bestRating": "5"
                        },"author": {
                        "@type": "Person",
                        "name": "Tri Thanh Alan"
                    }}
                 }`

const educationSchema = `{ "@context": "http://schema.org",
"@type": "EducationEvent",
"name": "Sotware Training Institute In Chennai with Job Placement",
"description": "Crampete is the no.1 software training institute in Chennai with 100% job placement. This best coaching centre offers IT training courses for the student and freshers.",
"performer": "Crampete",
"organizer": {
    "name":"Crampete",
    "url":"https://www.crampete.com/"
},
"image": "https://crampete-staticfiles.s3.ap-south-1.amazonaws.com/images/software-training-institute-in-chennai/software-training-institute-in-chennai-with-job-placement.png",
"eventAttendanceMode": "https://schema.org/MixedEventAttendanceMode",
"eventStatus": "https://schema.org/EventScheduled",
"location": [{
    "@type": "VirtualLocation",
    "url" : "https://www.crampete.com/software-training-institute-in-chennai/"
},{
    "@type": "Place",
    "name": "Crampete's Software Training Institute in Chennai with Job Placement",
    "address": {
        "@type": "PostalAddress",
        "name": "Chennai,India",
        "addressLocality": "Level -1, 132, Habibullah Rd, Parthasarathi Puram, T. Nagar, Chennai, Tamil Nadu 600017",
        "addressRegion": "Tamil Nadu"
    },
    "geo": {
        "@type": "GeoCoordinates",
        "latitude": "13.0505213",
        "longitude": "80.2344202"
    }
}],
"offers": {
    "@type": "AggregateOffer",
    "lowPrice": "5000.0000",
    "highPrice": "35000.0000",
    "url": "https://www.crampete.com/software-training-institute-in-chennai/",
    "availability": "http://schema.org/InStock",
    "validFrom": "2021-02-10",
    "price": "25000.0000",
    "priceCurrency": "INR"
},
"startDate": "2021-02-10",
"endDate": "2021-06-10",
"url": "https://www.crampete.com/software-training-institute-in-chennai/",
"duration": "3" }`;

const SoftwareTrainingInstitute = () => {

    const queryString = global.window?.location.search
    const urlParams = new URLSearchParams(queryString)

    const [utmParams] = useState({
        utmSource: urlParams.get("utm_source") || "",
        utmMedium: urlParams.get("utm_medium") || "",
        utmCampaign: urlParams.get("utm_campaign") || "",
    })

    const settings = {
        dots: true, infinite: true, speed: 500, slidesToShow: 3, slidesToScroll: 1, initialSlide: 0, autoplay: false, centerMode: true, autoplaySpeed: 500, cssEase: "linear", adaptiveHeight: true, responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    initialSlide: 2
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };

    const [activeId, setActiveId] = useState('0');
    const [showPlacements, setShowPlacements] = useState(false);
    const [courseModalShow, setCourseModalShow] = useState(false)

    const toggleActive = id => {
        // return false;
        if (activeId === id) {
            setActiveId(null);
        } else {
            setActiveId(id);
        }
    }

    const handleCourseModal = () => {
        setCourseModalShow(!courseModalShow)
    }

    return (
        <Layout title="Software Training Institute in Chennai with Job Placement" description="Crampete is the no.1 software training institute in Chennai with 100% job placement. This best coaching centre offers IT training courses for the student and freshers." url="https://www.crampete.com/software-training-institute-in-chennai/" courseSchema={courseSchema} videoSchema={videoSchema} educationSchema={educationSchema} productSchema={productSchema}  >
            {courseModalShow && <CourseForm utm={utmParams} course={`Software Training Institute in Chennai`} setCourseModalShow={setCourseModalShow} />}
            <main id="sti">
                <section id="sti-banner">
                    <div className="container">
                        <h1>Software Training Institute in Chennai</h1>
                        <div className="divider"></div>
                        <div className="row">
                            <div className="col-md-9">
                                <p><strong>Software Training Institutes in Chennai</strong> are umpteen in number to satisfy the growing demand for quality software professionals in the city and country at large. And Crampete is rated as the <strong>#1 Software training institute in Chennai</strong> with placement by many of the leading national level dailies.</p>
                                <p>With automation and new information technology trends like Full stack development, Data Science, IoT, AI, Machine Learning etc are on rise, and facing a huge supply demand gap, we are committed to produce quality resources in these tech areas. Crampete is one of the <strong>top ten software training institutes in Chennai</strong> with its base in Singapore [ <a href="https://timesofindia.indiatimes.com/home/education/news/crampete-sets-up-learning-centres-to-boost-the-rate-of-completion-of-online-tech-courses/articleshow/74518901.cms" target="_blank" rel="noreferrer">You can check Times of India’s coverage about us here</a> ]. To understand more about the software training courses offered by us lets us take you further deep into our course structure.</p>
                            </div>
                            <div className="col-md-3">
                                <video controls poster="https://crampete-staticfiles.s3.ap-south-1.amazonaws.com/images/software-training-institute-in-chennai/crampete-about-video-thumbnail.jpg">
                                    <source src="https://crampete-staticfiles.s3.ap-south-1.amazonaws.com/video/software-training-institute-in-chennai.mp4" type="video/mp4" />
                                    Your browser does not support the video tag.
                                </video>
                            </div>
                        </div>
                    </div>
                </section>
                <section id="sti-about">
                    <div className="container">
                        <h2>About Crampete</h2>
                        <p>Crampete is headquartered at Singapore having its offices in India, Malaysia, Oman and Singapore. Crampete is one of the <strong>top ten Software Training Institute in Chennai</strong> which has on-demand courses in Web Development and Data Science (currently). Crampete is an open source EduTech company providing software training and 100% placement to its students across the globe. Crampete is a part of Brain Valley in providing the software training to students across India through Self Paced, Online training and Online Live sessions. Some of our clients include [Kanan International, Quizky, Brainvalley].</p>
                        <p>Brainvalley, Crampete’s parent company is an IT company providing end to end technical support to all our products that are customised for our customers’ benefit. With 100+ customers in our kitty, we are India’s most trusted software training and placement organisation with our much loyal and long-standing employee force across Chennai, Bangalore, Bhubaneswar, Coimbatore, Delhi and Kolkata. Such a national and global level presence makes Crampete one of the top software training institutes in Chennai, India and in the world at large.</p>
                        <div className="row">
                            <div className="col-md-3 col-6">
                                <div className="about-icons-wrapper">
                                    <img src="https://crampete-staticfiles.s3.ap-south-1.amazonaws.com/images/software-training-institute-in-chennai/videos-developed.png" alt="Videos Developed" />
                                    <p>Videos Develop</p>
                                    <i>4800</i>
                                </div>
                            </div>
                            <div className="col-md-3 col-6">
                                <div className="about-icons-wrapper">
                                    <img src="https://crampete-staticfiles.s3.ap-south-1.amazonaws.com/images/software-training-institute-in-chennai/certified-teachers-icon.png" alt="Certified Teachers" />
                                    <p>Certified Teachers</p>
                                    <i>26</i>
                                </div>
                            </div>
                            <div className="col-md-3 col-6">
                                <div className="about-icons-wrapper">
                                    <img src="https://crampete-staticfiles.s3.ap-south-1.amazonaws.com/images/software-training-institute-in-chennai/students-enrolled.png" alt="Students Enrolled" />
                                    <p>Students Enrolled</p>
                                    <i>4789</i>
                                </div>
                            </div>
                            <div className="col-md-3 col-6">
                                <div className="about-icons-wrapper">
                                    <img src="https://crampete-staticfiles.s3.ap-south-1.amazonaws.com/images/software-training-institute-in-chennai/percentage-placed.png" alt="Percentage Placed" />
                                    <p>Percentage Placed</p>
                                    <i>89</i>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section id="course-duration">
                    <div className="container">
                        <h2>Software Courses in Chennai - Duration</h2>
                        <p>Being the best software training institute and coaching class in Chennai, Crampete courses are well in demand and are categorised into two categories.</p>
                        <ul>
                            <li>Current Trending software Courses</li>
                            <li>Master software Courses</li>
                        </ul>
                        <div className="mt-4">
                            <p>Crampete a Software Training Institute in Chennai courses are well in demand and are categorised into two headings Current Trending Courses and Master program Courses</p>
                        </div>
                        <div className="master-courses-wrapper">
                            <div className="container">
                                <h3>Master software Courses in Chennai are as follows:</h3>
                                <div className="w-100 text-center mt-5">
                                    <img src="https://crampete-staticfiles.s3.ap-south-1.amazonaws.com/images/software-training-institute-in-chennai/master-software-courses-in-chennai.png" alt="Master Software Courses In Chennai" />
                                </div>
                                <div className="courses-wrapper">
                                    <div className="row text-center">
                                        <div className="col-md-4">
                                            <div className="courses-inner">
                                                <a href="/courses/full-stack-web-development-online-course/" target="_blank" rel="noreferrer">Full-Stack Developer</a>
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="courses-inner">
                                                <a href="/courses/data-science-online-course/" target="_blank" rel="noreferrer">Data Science</a>
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="courses-inner">
                                                <a href="/courses/front-end-online-course/" target="_blank" rel="noreferrer">Frontend</a>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="courses-inner">
                                                <a href="/courses/back-end-online-course/" target="_blank" rel="noreferrer">Backend</a>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="courses-inner">
                                                <a href="/courses/digital-marketing-offline-courses/" target="_blank" rel="noreferrer">Digital Marketing</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="current-trending-courses">
                            <div className="container">
                                <h3>Trending software in courses in Chennai by Crampete are as follows:</h3>
                                <div className="w-100 text-center mt-5">
                                    <img src="https://crampete-staticfiles.s3.ap-south-1.amazonaws.com/images/software-training-institute-in-chennai/current-trending-software-courses-in-chennai.png" alt="Current Trending Software Courses In Chennai" />
                                </div>
                                <div className="courses-wrapper">
                                    <div className="row text-center">
                                        <div className="col-md-3 col-6">
                                            <div className="courses-inner">
                                                <a href="/courses/python-online-course/" target="_blank" rel="noreferrer">Python</a>
                                            </div>
                                        </div>
                                        <div className="col-md-3 col-6">
                                            <div className="courses-inner">
                                                <a href="/courses/reactjs-online-course/" target="_blank" rel="noreferrer">React JS</a>
                                            </div>
                                        </div>
                                        <div className="col-md-3 col-6">
                                            <div className="courses-inner">
                                                <a href="/courses/mongodb-online-course/" target="_blank" rel="noreferrer">MongoDB</a>
                                            </div>
                                        </div>
                                        <div className="col-md-3 col-6">
                                            <div className="courses-inner">
                                                <a href="/courses/php-online-course/" target="_blank" rel="noreferrer">PHP</a>
                                            </div>
                                        </div>
                                        <div className="col-md-3 col-6">
                                            <div className="courses-inner">
                                                <a href="/courses/c-programming-online-course/" target="_blank" rel="noreferrer">C Program</a>
                                            </div>
                                        </div>
                                        <div className="col-md-3 col-6">
                                            <div className="courses-inner">
                                                <a href="/courses/c-plus-plus-programming-online-course/" target="_blank" rel="noreferrer">C++ Program</a>
                                            </div>
                                        </div>
                                        <div className="col-md-3 col-6">
                                            <div className="courses-inner">
                                                <a href="/courses/java-online-course/" target="_blank" rel="noreferrer">Java Program</a>
                                            </div>
                                        </div>
                                        <div className="col-md-3 col-6">
                                            <div className="courses-inner">
                                                <a href="/courses/css-online-course/" target="_blank" rel="noreferrer">CSS</a>
                                            </div>
                                        </div>
                                        <div className="col-md-3 col-6">
                                            <div className="courses-inner">
                                                <a href="/courses/bootstrap-online-course/" target="_blank" rel="noreferrer">Bootstrap</a>
                                            </div>
                                        </div>
                                        <div className="col-md-3 col-6">
                                            <div className="courses-inner">
                                                <a href="/courses/javascript-online-course/" target="_blank" rel="noreferrer">Javascript</a>
                                            </div>
                                        </div>
                                        <div className="col-md-3 col-6">
                                            <div className="courses-inner">
                                                <a href="/courses/git-online-course/" target="_blank" rel="noreferrer">Git</a>
                                            </div>
                                        </div>
                                        <div className="col-md-3 col-6">
                                            <div className="courses-inner">
                                                <a href="/courses/angularjs-online-course/" target="_blank" rel="noreferrer">Angular JS</a>
                                            </div>
                                        </div>
                                        <div className="col-md-3 col-6">
                                            <div className="courses-inner">
                                                <a href="/courses/nodejs-online-course/" target="_blank" rel="noreferrer">Node JS</a>
                                            </div>
                                        </div>
                                        <div className="col-md-3 col-6">
                                            <div className="courses-inner">
                                                <a href="/courses/data-analytics-online-course/" target="_blank" rel="noreferrer">Data Analytics</a>
                                            </div>
                                        </div>
                                        <div className="col-md-3 col-6">
                                            <div className="courses-inner">
                                                <a href="/courses/html-online-course/" target="_blank" rel="noreferrer">HTML</a>
                                            </div>
                                        </div>
                                        <div className="col-md-3 col-6">
                                            <div className="courses-inner">
                                                <a href="/courses/web-design-training-in-chennai/" target="_blank" rel="noreferrer">Web Designing</a>
                                            </div>
                                        </div>
                                        <div className="col-md-3 col-6">
                                            <div className="courses-inner">
                                                <a href="/courses/mean-stack-developer-course/" target="_blank" rel="noreferrer">MEAN Stack</a>
                                            </div>
                                        </div>
                                        <div className="col-md-3 col-6">
                                            <div className="courses-inner">
                                                <a href="/courses/mern-stack-course-in-chennai/" target="_blank" rel="noreferrer">MERN Stack</a>
                                            </div>
                                        </div>
                                        <div className="col-md-3 col-6">
                                            <div className="courses-inner">
                                                <a href="/courses/verbal-ability-online-course/" target="_blank" rel="noreferrer">Verbal Ability</a>
                                            </div>
                                        </div>
                                        <div className="col-md-3 col-6">
                                            <div className="courses-inner">
                                                <a href="/courses/quantitative-aptitude-online-course/" target="_blank" rel="noreferrer">Aptitude</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section id="sti-chennai">
                    <div className="container">
                        <h2>Software training institutes in chennai with placement</h2>
                        <p>Do crampete software training institutes in Chennai provide placements?Software Training Institute in Chennai with Placements are very essential for any student taking up any professional course. Crampete strives hard to get their students best of all worlds through their best in the market software training and placement. The companies that recruit students in the technologies we offer are Accenture, Capgemini, Cognizant, Linkedin, Zoho and many more big names to add.</p>
                        <p>All this happens through Crampete’s rigorous coaching in data science, artificial intelligence, machine learning, networking, cloud computing, programming languages, corporate programming, web designing, QA testing, SAP Training, soft skills development on a rigorous basis. At least 5 mock interviews are conducted and 2 realtime interviews are also conducted for better exposure of the candidates. Along with the mock interviews, a professional CV is also prepared for the students. With our interview questions and  rigorous software training our students are guaranteed with 100% Placement is assured by Crampete.</p>
                        <small><strong>Also Read: </strong><a href="https://www.aninews.in/news/business/crampete-sets-up-learning-centres-to-boost-the-rate-of-completion-of-online-tech-courses20200306130943/" target="_blank" rel="noreferrer">Why ANI feels we are best software training institute in chennai with placement</a></small>
                    </div>
                </section>
                <section id="sti-placed-students">
                    <div className="container">
                        <h2>Placed students list from our software training center in Chennai</h2>
                        <p>With Crampete being one of the top software institutes in Chennai, the 100% placement track record our strength as shown in the table below ranging from freshers to experience in the companies:(just few sample)</p>
                        <Table className="sti-table" bordered hover>
                            <thead>
                                <tr>
                                    <th>Student Placed by Brainvalley</th>
                                    <th>Company Name</th>
                                </tr>
                            </thead>
                            <tbody>
                                {showPlacements &&
                                    <React.Fragment>
                                        <tr>
                                            <td>Tri Thanh Alan</td>
                                            <td>Hexaware Technologies</td>
                                        </tr>
                                        <tr>
                                            <td>Pragrith</td>
                                            <td>Capgemini</td>
                                        </tr>
                                        <tr>
                                            <td>Joshiba Fernando</td>
                                            <td>Accenture</td>
                                        </tr>
                                        <tr>
                                            <td>Karthik</td>
                                            <td>TCS</td>
                                        </tr>
                                        <tr>
                                            <td>Priya</td>
                                            <td>Infosys</td>
                                        </tr>
                                        <tr>
                                            <td>Geetha</td>
                                            <td>TCS</td>
                                        </tr>
                                        <tr>
                                            <td>Ramakrishnan</td>
                                            <td>Wipro</td>
                                        </tr>
                                        <tr>
                                            <td>Senthil</td>
                                            <td>HP</td>
                                        </tr>
                                        <tr>
                                            <td>Raja</td>
                                            <td>Ford</td>
                                        </tr>
                                        <tr>
                                            <td>Soundar</td>
                                            <td>NTT</td>
                                        </tr>
                                        <tr>
                                            <td>Maniraj</td>
                                            <td>Brainvalley</td>
                                        </tr>
                                        <tr>
                                            <td>Kumar</td>
                                            <td>Cognizant</td>
                                        </tr>
                                        <tr>
                                            <td>Shobana</td>
                                            <td>Wipro</td>
                                        </tr>
                                        <tr>
                                            <td>Vasanth</td>
                                            <td>Amazon</td>
                                        </tr>
                                    </React.Fragment>
                                }
                                <tr onClick={() => setShowPlacements(!showPlacements)}>
                                    <td colSpan="2">
                                        <div className="tableArrows">
                                            <FontAwesomeIcon icon={showPlacements ? faAngleDoubleUp : faAngleDoubleDown} />
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </Table>
                        <div className="w-100 mt-5">
                            <img src="https://crampete-staticfiles.s3.ap-south-1.amazonaws.com/images/software-training-institute-in-chennai/crampete-placement-companies-infographics.png" alt="Crampete Placement Companies Infographics" />
                        </div>
                    </div>
                </section>
                <section id="rated-top-five">
                    <div className="container">
                        <h2>Why we are rated as Top 5 training institutes in Chennai</h2>
                        <p>Crampete is rated as one of the top 5 IT training institutes in Chennai for a few yet distinct reasons. It is one of the leading software developer training institutes in Chennai which has the following reasons in its kitty to be proud of:</p>
                        <ul>
                            <li>Crampete boasts of its 100% placement record till date with high-end software organisations like Accenture, Capgemini, Zoho etc.</li>
                            <li>The fees charged by Crampete for its courses on all three modes of software developer training are on par with the industry.</li>
                            <li>The exhaustive curriculum of Crampete is their major strength and with the professional trainers, the job of training students becomes a cakewalk to churn out best software developers/engineers in Chennai.</li>
                            <li>With a wide variety of mock interviews and 2 real-time interviews conducted by Crampete, the exposure for students is much exhaustive and intrinsic for them to become an asset to the corporate world.</li>
                            <li>The Main  advantage of learning things at their own pace with Flexi timings, learning software development is as simple as a kindergarten student.</li>
                            <li>The Cramdegree awarded to students is recognised nationally and internationally.</li>
                            <li>The advantage of taking up sessions with Crampete are varied learning on student’s pace, online or on-site.</li>
                        </ul>
                    </div>
                </section>
                <section id="affordable-fees">
                    <div className="container">
                        <h2>Affordable Software coaching Fees</h2>
                        <ul>
                            <li>Affordable</li>
                            <li>Multiple installments</li>
                            <li>3 class money return policy(*)</li>
                            <li>Assured placement or money return policy(*)</li>
                        </ul>
                        <p>Crampete learning and tutorials are made interesting yet very comfortable for all to become professionals in life. Crampete offers an array of software developer courses with a money-back guarantee if the student is not getting a job on finishing the course.</p>
                        <p>Each of the software development courses conducted by Crampete is structured and is worked upon with the flexibility of learning for the student. The best part with Crampete is the flexibility of paying up the fees in multiple instalments for the ease and comfort of the students. The students with a great academic track database, 3 classes are provided free for them. The list of courses and its fees are listed below:</p>
                        <Table className="sti-table" bordered hover>
                            <thead>
                                <tr>
                                    <th>List of Courses</th>
                                    <th>Fees</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>Full Stack Web Development</td>
                                    <td>INR 30,000/-</td>
                                </tr>
                                <tr>
                                    <td>Data Science</td>
                                    <td>INR 30,000/-</td>
                                </tr>
                                <tr>
                                    <td>Front End Development</td>
                                    <td>INR 20,000/-</td>
                                </tr>
                                <tr>
                                    <td>Back End Development</td>
                                    <td>INR 20,000/-</td>
                                </tr>
                                <tr>
                                    <td>Digital Marketing</td>
                                    <td>INR 25,000/-</td>
                                </tr>
                                <tr>
                                    <td>Web Design</td>
                                    <td>INR 15,000/-</td>
                                </tr>
                                <tr>
                                    <td>React Js &amp; Javascript</td>
                                    <td>INR 10,000/-</td>
                                </tr>
                                <tr>
                                    <td>MongoDB</td>
                                    <td>INR 5,000/-</td>
                                </tr>
                                <tr>
                                    <td>Angular</td>
                                    <td>INR 5,000/-</td>
                                </tr>
                            </tbody>
                        </Table>
                    </div>
                </section>
                <section id="sti-trainers">
                    <div className="container">
                        <h2>Who are the trainers for the software training institutes in Chennai?</h2>
                        <p>Crampete trainers are much qualified and trained with much experience in the field of software training and placement. With Crampete’s reputation of one of the top software training institutes in Chennai, the trainers that we rope in are professionals from varied IT organisations, Professors in IT field, trainers in IT field who impart our curriculum with up to date examples and practical experiences from the industry. Such trainers bring out the best from the rest and make the sessions lively and interactive. Such interactive sessions mould our students into better professionals.</p>
                        <ul>
                            <li>Over 17+ years of industry experience.</li>
                            <li>Full expertise in web technologies.</li>
                            <li>Professionally qualified,friendly,committed and enthusiastic mentors from top MNC’s.</li>
                            <li>Excellent knowledge in all aspects of web development.</li>
                            <li>Trained over 500+ students.</li>
                            <li>Completed over 30+ projects.</li>
                        </ul>
                    </div>
                </section>
                <section id="sti-batch-size">
                    <div className="container">
                        <h2>Batch size for our IT training classes in Chennai</h2>
                        <p>At Crampete, we make sure that the software development training imparted to the students is viable and emphatic. In order to do so we created a batch size of 5 to 10 students per batch. This was created to have a good student trainer ratio and the training be imparted on a one to one basis effectively. Such training is much favourable for better understanding of the concepts deployed.</p>
                    </div>
                </section>
                <section id="sti-flexible-timings">
                    <div className="container">
                        <h2>Flexible timings</h2>
                        <p>In this fast-paced life, flexible timings are the need of the hour and at Crampete we value this with respect to our students and tutors. We provide morning sessions, evening sessions, weekdays and weekend sessions for the benefit of working, job seeking and student population. The flexibility in taking up sessions with Crampete helps them to manage their profession, job seeking and student life along with our software development training with ease.We show them the road to success.</p>
                        <ul>
                            <li>In fact, our weekend software testing training/courses is handled by manager level trainers.</li>
                            <li>We also conduct weekend digital marketing courses in Chennai for the benefit of business owners and startup enthusiasts.</li>
                            <li>Our weekend developer course in Chennai comes with a 100% placement guarantee.</li>
                        </ul>
                    </div>
                </section>
                <section id="sti-cetificate">
                    <div className="container">
                        <h2>Software training Certifications in Chennai</h2>
                        <div className="row">
                            <div className="col-md-9">
                                <div className="sti-certificate-content">
                                    <p>As we provide the professional training in software development such as Full-Stack, Data Science, Java, Python, devops, full stack Javascript frameworks(version control), Django, agile software development-process, ajax, redux, Java, ReactJs, (hypertext markup-language) html and css (cascading style sheets), mobile application, Const, ASP.Net MVC, CMS and web content management, object oriented programming-languages,web coding, web development courses, photoshop etc, our certification for such courses is world-renowned. Cramdegree certification by Crampete is viable in India and across international borders and is welcomed by varied IT majors as our students surpass with flying colours at work as compared to others.</p>
                                    <p>As we have our branches in Singapore and Malaysia our Cramdegree is viable across international borders with the same visibility as India. Our certification is authentic and applicable to all the organisations falling in the purview of software development.</p>
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="sti-cetificate-wrapper">
                                    <img src="https://crampete-staticfiles.s3.ap-south-1.amazonaws.com/images/software-training-institute-in-chennai/software-training-certifications-in-chennai.png" alt="Software Training Certifications In Chennai" />
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section id="sti-training-methodology">
                    <div className="container">
                        <h2>Training methodology and pedagogy</h2>
                        <p>Training conducted at Crampete is based on practical exposure to the real-life scenarios in the IT field. This is an essential part of Crampete training and software development course. The course curriculum layout has become an all-inclusive and comprehensive training module.</p>
                        <ul>
                            <li>We at Crampete provide assignments, quizzes to test the knowledge gained by our students on the models conducted by our eminent trainers.</li>
                            <li>Worksheets are provided to ingrain the conceptual learning and practical aspects of the concepts taken up.</li>
                            <li>The recorded sessions are also uploaded on our website for the best usage by the students in their leisure time.</li>
                        </ul>
                        <p>We at Crampete believe in Practice makes the person perfect, so we provide practical training through mini-projects and other live projects for the students. This helps them to understand the real-life scenario in the organisations.</p>
                    </div>
                </section>
                <section id="sti-projects">
                    <div className="container">
                        <h2>Projects</h2>
                        <p>On the completion of the Crampete modules, the students are provided with mini-projects to get the feel of the live scenario in an organisation. In such a situation one gets to apply the learning to a real-life situation leading to improved confidence in working projects and is a great foundation for one’s capstone project. The mini-projects are very important and carry credits towards the successful completion of the course.</p>
                        <div className="row">
                            <div className="col-md-4">
                                <div className="sti-project-wrapper"><p>Personal Portfolio Page</p></div>
                            </div>
                            <div className="col-md-4">
                                <div className="sti-project-wrapper"><p>Landing Page for a Product</p></div>
                            </div>
                            <div className="col-md-4">
                                <div className="sti-project-wrapper"><p>Interactive Websites (DOM Operations)</p></div>
                            </div>
                        </div>
                        <p>A capstone project with Crampete is a final stage of getting certified by Crampete and it happens at the end of 4 months when both web applications classes are completed, The project will be based on real-time web apps. You get to build your own web app as a part of the project. Your trainer and mentor will guide you through the project.</p>
                        <p>The capstone project will be for the last few weeks of the developer course. Once you complete your project, you will present it to the panel of experts who will assess your project. This is a significant part of your course and carries heavy weightage in terms of credits.</p>
                        <div className="row">
                            <div className="col-md-4">
                                <div className="sti-project-wrapper"><p>Questions &amp; Answers Site (Like Quora)</p></div>
                            </div>
                            <div className="col-md-4">
                                <div className="sti-project-wrapper"><p>School Management System</p></div>
                            </div>
                            <div className="col-md-4">
                                <div className="sti-project-wrapper"><p>Project Management Tool (Like Trello)</p></div>
                            </div>
                        </div>
                    </div>
                </section>
                <section id="sti-student-reviews">
                    <div className="container">
                        <h2>Student Reviews</h2>
                        <p>Students of Crampete are well placed in the industry and the reviews they provide is a boost for our growth. More than 1000s of students have been trained and placed in different IT majors across the world and each one of them has made their point clear that Crampete has been the stepping stone for them to rise up high in their profession today.</p>
                        <div className="w-100">
                            <Slider {...settings}>
                                <div className="sti-testimonial-wrapper">
                                    <img className="sti-left-quote" src="https://crampete-staticfiles.s3.ap-south-1.amazonaws.com/images/software-training-institute-in-chennai/left-quote.png" alt="Left Quote" />
                                    <div className="sti-testimonial-name">
                                        <p>Tri Thanh Alan</p>
                                        <img src="https://crampete-staticfiles.s3.ap-south-1.amazonaws.com/images/testimonials/Alan.jpg" alt="Tri Thanh Alan" />
                                    </div>
                                    <p className="sti-testimonial-content">Before enrolling with Crampete, I had zero knowledge about software programming and I wanted to pursue a career in the same. The course at Crampete changed my career upfront. I am enriched with knowledge about software programming due to the well experienced friendly trainers at Crampete. A job at Hexaware as a Full Stack Developer is the crowning glory which is my dream come true.</p>
                                </div>
                                <div className="sti-testimonial-wrapper">
                                    <img className="sti-left-quote" src="https://crampete-staticfiles.s3.ap-south-1.amazonaws.com/images/software-training-institute-in-chennai/left-quote.png" alt="Left Quote" />
                                    <div className="sti-testimonial-name">
                                        <p>Pragrith</p>
                                        <img src="https://crampete-staticfiles.s3.ap-south-1.amazonaws.com/images/testimonials/Pragrith.jpg" alt="Pragrith" />
                                    </div>
                                    <p className="sti-testimonial-content">I aspired to become a Data Scientist and to become so I needed a good learning platform. On approaching Crampete I was provided with enough information to understand what Data Science was. On enrolling, I was trained by their experienced trainers in Python, Statistics and Machine Learning to become a Data Scientist with Capgemini today. I recommend Crampete for Data Science aspirants as its the best place to learn and get 100% placement guarantee.</p>
                                </div>
                                <div className="sti-testimonial-wrapper">
                                    <img className="sti-left-quote" src="https://crampete-staticfiles.s3.ap-south-1.amazonaws.com/images/software-training-institute-in-chennai/left-quote.png" alt="Left Quote" />
                                    <div className="sti-testimonial-name">
                                        <p>Joshiba</p>
                                        <img src="https://crampete-staticfiles.s3.ap-south-1.amazonaws.com/images/testimonials/Joshiba.jpg" alt="Joshiba" />
                                    </div>
                                    <p className="sti-testimonial-content">With digital marketing becoming a trend with all the businesses across the world, being a marketing professional I wanted to understand and learn what it was. I approached Crampete for the same and took up their weekend classes on DIgital Marketing. I learnt about SEO, SMM, SEM, IM and the concepts were so well developed that learning was like a child’s play. The tutors with Crampete are well trained and friendly making learning much enjoyable.</p>
                                </div>
                            </Slider>
                        </div>
                    </div>
                </section>
                <section id="sti-life-at-crampete">
                    <div className="container">
                        <h2>Life at Crampete</h2>
                        <p>Life at Crampete is a fun way of learning and engaging in the most wanted career options available for software testing, coding, computing and programming languages across the world. One can become a best programmer after completing a desired computer science course over here.The friendly environment and experienced teachers make the learning process much enjoyable and interesting. Classes are akeen in the form of classroom training and online classes. Varied aspects of each project are taught with easy methods and fun ways of learning.</p>
                    </div>
                </section>
                <section id="sti-awards">
                    <div className="container">
                        <h2>Awards for being the best software training institute</h2>
                        <p>Crampete has become a name to reckon with in software development training with quite a few awards to name. The awards earned by Crampete are Industry Academia Excellence Award for most promising upskilling academy for emerging IT skills, Indian Education Awards 2020 for Skill Learning in 2020. All these accolades are for the performance of trainers, employees and students of Crampete who make this possible with all their efforts and hard work.</p>
                        <div className="sti-awards-wrapper-outer mt-4">
                            <img className="ati-award-bg" src="https://crampete-staticfiles.s3.ap-south-1.amazonaws.com/images/software-training-institute-in-chennai/award-background.png" alt="Award Background" />
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="sti-awards-wrapper text-center">
                                        <img src="https://crampete-staticfiles.s3.ap-south-1.amazonaws.com/images/software-training-institute-in-chennai/industry-academia-excellence-award.png" alt="Industry Academia Excellence Award" />
                                        <h3>Industry Academia Excellence Award</h3>
                                        <p>For most promising upskilling academy for emerging IT</p>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="sti-awards-wrapper text-center">
                                        <img src="https://crampete-staticfiles.s3.ap-south-1.amazonaws.com/images/software-training-institute-in-chennai/india-education-awards-2020.png" alt="India Education Awards 2020" />
                                        <h3>India Education Awards 2020</h3>
                                        <p>For Skill Learning in 2020</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <p className="mt-5">All these accolades are for the performance of trainers, employees and students of Crampete who make this possible with all their efforts and hard work.</p>
                    </div>
                </section>
                <section id="sti-location">
                    <div className="container">
                        <h2>Locations of our software training centers</h2>
                        <p>Crampete is a growing organization with branches across <span>India</span> and few overseas places like <span>Singapore</span>, <span>United Kingdom</span>, <span>Kuala Lumpur</span> and <span>Zambia</span>. Across Pan India we are located in <span>Bangalore</span>, <span>Bhubaneswar</span>, <span>Coimbatore</span>, <span>Chennai</span> and <span>Raipur</span>. We are glad to announce much more training centres coming up on the national level to enhance our growth and to provide training to the students.</p>
                    </div>
                </section>
                <section id="sti-branches-chennai">
                    <div className="container">
                        <h2>Branches / Centers in Chennai</h2>
                        <p>Crampete is spreading its arms with training institutes in Chennai currently located at <span>T.Nagar</span> near LIC building. We are pleased to announce that our branches cater at Anna Nagar, OMR, Velacherry, Ramapuram, Nungambakkam, Tambaram, Navalur and Vadapalani and many more training centres coming up to expand our growth.</p>
                        <p>P.S: To get the best coaching, you must not mind travelling a few extra KMs. But for some reasons if you cannot travel to our centers, we recommend you to check software courses in Chennai from institutes like Infotech solutions, nareshit, besant technologies karapakkam, accord, aimore tamil nadu etc.</p>
                    </div>
                </section>
                <section id="sti-faqs">
                    <div className="container">
                        <h2>FAQs</h2>
                        <Accordion defaultActiveKey="0">
                            {stiFaqs.map((faq) => {
                                return (
                                    <Card key={faq.id} itemprop="mainEntity" itemtype="https://schema.org/Question">
                                        <Card.Header itemprop="name">
                                            <Accordion.Toggle onClick={() => toggleActive(faq.id)} as={Button} variant="link" eventKey={faq.id}>
                                                <h4>{faq.question}</h4>
                                            </Accordion.Toggle>
                                            <FontAwesomeIcon icon={activeId === faq.id ? faArrowUp : faArrowDown} />
                                        </Card.Header>
                                        <Accordion.Collapse eventKey={faq.id}>
                                            <Card.Body itemprop="acceptedAnswer" itemtype="https://schema.org/Answer"><p itemprop="text">{faq.answer}</p></Card.Body>
                                        </Accordion.Collapse>
                                    </Card>
                                )
                            })}
                        </Accordion>
                    </div>
                </section>
            </main>
        </Layout>
    )
}

export default SoftwareTrainingInstitute