import React, { useState } from "react"
import { Button, Modal, Form, Alert } from "react-bootstrap"
import PhoneInput from 'react-phone-number-input'
import 'react-phone-number-input/style.css'
import { navigate } from 'gatsby'
import { quizkyCrampeteAPI } from '../config'

const CourseForm = props => {
  const {
    setCourseModalShow,
    downloadSyllabus,
    setDownloadSyllabusModal,
    course,
    brochureURL,
    utm
  } = props

  let utmSource = utm.utmSource ? utm.utmSource : 'organic';
  const url = global.window?.location.href;
 
  const [success, setSuccess] = useState(false)
  const [failure, setFailure] = useState(false)

  const [formDatas, setFormDatas] = useState({
    courseName: "",
    courseEmail: "",
    courseNumber: 0,
    // courseCity: null
  })

  const [validation, setValidation] = useState({
    courseErrName: null,
    courseErrEmail: null,
    courseErrNumber: null,
    // courseCity: null
  })

  const handleCloseModal = () => {
    if (downloadSyllabus) {
      setDownloadSyllabusModal(false)
    } else {
      setCourseModalShow(false)
    }
  }

  const handleChangeCourseName = event => {
    setValidation(prevState => {
      return { ...prevState, courseErrName: null }
    })

    let currentValue = event.target.value
    setFormDatas(prevState => {
      return { ...prevState, courseName: currentValue }
    })
  }

  const handleChangeCourseEmail = event => {
    setValidation(prevState => {
      return { ...prevState, courseErrEmail: null }
    })

    let currentValue = event.target.value
    setFormDatas(prevState => {
      return { ...prevState, courseEmail: currentValue }
    })
  }

  const handleChangeCourseNumber = event => {
    setValidation(prevState => {
      return { ...prevState, courseErrNumber: null }
    })

    let currentValue = event
    setFormDatas(prevState => {
      return { ...prevState, courseNumber: currentValue }
    })
  }

  // const handleChangeCourseCity = (event) => {

  //   setValidation((prevState) => {
  //     return { ...prevState, courseErrCity: null };
  //   });

  //   let currentValue = event.target.value;
  //   setFormDatas((prevState) => {
  //     return { ...prevState, courseCity: currentValue };
  //   });
  // }

  const validateForm = () => {
    setSuccess(false)
    setFailure(false)

    if (!formDatas.courseName) {
      setValidation(prevState => {
        return { ...prevState, courseErrName: "Please enter your name" }
      })
    } else {
      setValidation(prevState => {
        return { ...prevState, courseErrName: null }
      })
    }

    if (
      !formDatas.courseEmail ||
      !/(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@[*[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+]*/.test(
        formDatas.courseEmail
      )
    ) {
      setValidation(prevState => {
        return { ...prevState, courseErrEmail: "Please enter a valid Email" }
      })
    } else {
      setValidation(prevState => {
        return { ...prevState, courseErrEmail: null }
      })
    }

    if (!formDatas.courseNumber) {
      setValidation(prevState => {
        return { ...prevState, courseErrNumber: "Please enter a valid number" }
      })
    } else {
      setValidation(prevState => {
        return { ...prevState, courseErrNumber: null }
      })
    }

    // if(formDatas.courseCity === null) {
    //   setValidation((prevState) => {
    //     return { ...prevState, courseErrCity: 'Please select a city from the drop down' }
    //   });
    // } else {
    //   setValidation((prevState) => {
    //     return { ...prevState, courseErrCity: null };
    //   });
    // }

    if (
      validation.courseErrName === null &&
      validation.courseErrEmail === null &&
      validation.courseErrNumber === null &&
      formDatas.courseName !== "" &&
      formDatas.courseEmail !== "" &&
      formDatas.courseNumber !== ""
      // formDatas.courseNumber.length === 10
    ) {

      document.getElementById("enquireNow").innerHTML = "Please wait..."

      const opts = {
        "companyCode": "crampete",
        "accountId": "master",
        "studentName": formDatas.courseName,
        "emailId": formDatas.courseEmail,
        "mobileNumber": formDatas.courseNumber,
        "referenceCourseId": course.Id,
        "referenceCourseName": course.Title,
        "source": utmSource,
        "url": url
      }

      // Sendy
      const sendyBody = 'name=' + formDatas.courseName + '&email=' + formDatas.courseEmail + '&list=Gsmlyu6kynWtV5owFKUASw&api_key=t4AYXM4X5RcK3frypG1N';
      const postSendyBody = new URLSearchParams(sendyBody);

      fetch('https://mailer.crampete.com/subscribe', {
        method: "POST",
        headers: { "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8" },
        mode: 'no-cors',
        body: postSendyBody,
      }).then(function (res) {
      }).catch((e) => console.error(e));
      // Sendy

      fetch(
        `${quizkyCrampeteAPI}/common-lead/zoho`,
        {
          method: "post",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(opts),
        }
      )
        .then(function (response) {
          return response.json()
        })
        .then(function (data) {
          if (data.success) {
            setSuccess(true)
            if (downloadSyllabus) {
              document.getElementById("enquireNow").innerHTML =
                "Download Brochure"
            } else {
              // document.getElementById("enquireNow").innerHTML = "Enquire"
              navigate('/thank-you/')
            }
            setFormDatas({
              courseName: "",
              courseEmail: "",
              courseNumber: 0,
            })
          } else {
            setFailure(true)
            if (downloadSyllabus) {
              document.getElementById("enquireNow").innerHTML =
                "Download Brochure"
            } else {
              document.getElementById("enquireNow").innerHTML = "Enquire"
            }
          }
        })
    }
  }

  return (
    <Modal
      id="course-enquiry-form"
      show={true}
      onHide={handleCloseModal}
      animation={true}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Button className="close" onClick={handleCloseModal}>
        <span aria-hidden="true">×</span>
      </Button>
      <Modal.Header>
        <Modal.Title>
          <img
            src="https://crampete-staticfiles.s3.ap-south-1.amazonaws.com/images/logos/crampete-c-logo.png"
            alt="Crampete"
          />
          Let's Get Started!
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {success && !downloadSyllabus && (
          <React.Fragment>
            <Alert variant="success" style={{ fontSize: "14px", textAlign: "center" }}  >Thanks, we've received your enquiry!</Alert>
          </React.Fragment>
        )}

        {success && downloadSyllabus && (
          <React.Fragment>
            <Alert variant="success" style={{ fontSize: "14px", textAlign: "center" }}>Thanks, please click{" "}<a href={brochureURL} target="_blank" rel="noopener noreferrer">here</a>{" "}to download your eBook</Alert>
          </React.Fragment>
        )}

        {failure && (
          <Alert
            variant="danger"
            style={{ fontSize: "14px", textAlign: "center" }}
          >
            Oops, something went wrong!
          </Alert>
        )}
        <Form>
          <Form.Group controlId="formBasicName">
            <Form.Control
              type="text"
              name="courseName"
              onChange={handleChangeCourseName}
              placeholder="Name"
              value={formDatas.courseName}
            />
            <Form.Text className="text-danger">
              {validation.courseErrName}
            </Form.Text>
          </Form.Group>
          <Form.Group controlId="formBasicEmail">
            <Form.Control
              type="email"
              name="courseEmail"
              onChange={handleChangeCourseEmail}
              placeholder="Email"
              value={formDatas.courseEmail}
            />
            <Form.Text className="text-danger">
              {validation.courseErrEmail}
            </Form.Text>
          </Form.Group>
          <Form.Group controlId="formBasicNumber">
            <PhoneInput defaultCountry="IN" value={formDatas.courseNumber === 0 ? "" : formDatas.courseNumber} placeholder="Mobile Number" onChange={handleChangeCourseNumber} />
            <Form.Text className="text-danger">
              {validation.courseErrNumber}
            </Form.Text>
          </Form.Group>
          <Form.Group className="text-center">
            <Button variant="primary" id="enquireNow" onClick={validateForm}>
              {downloadSyllabus ? "Download Brochure" : "Enquire"}
            </Button>
          </Form.Group>
        </Form>
      </Modal.Body>
    </Modal>
  )
}

export default CourseForm